<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
        :plan-options="planOptions" @refetch-data="refetchData" />
  
      <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
        :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <validation-observer ref="simpleRules">
        <div class="m-2">
          <!-- Table Top -->
          <b-row class="d-flex justify-content-between w-100">
            <!-- Per Page -->
            <b-col md="6" class="d-flex mt-1">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="per_page"
                class="per-page-selector d-inline-block mx-50"
                :options="['5', '10', '20', '50', '100']"
                @input="changePagination()"
              />
              <!-- Search -->
              <div class="d-flex align-items-center justify-content-start">
                <b-button variant="primary" @click="openModal">
                  <span class="text-nowrap" style="font-size: 15px"
                    >Add New Client</span
                  >
                </b-button>
              </div>
            </b-col>

            <b-modal
              hide-footer
              v-model="AddNewUser"
              no-close-on-backdrop
              @hidden="resetModal"
              centered
              size="lg"
            >
              <b-form ref="myForm">
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex flex-column align-items-center text-center"
                  >
                    <h2 style="font-size: 26px" class="font-weight-bold">
                      {{
                        ifEdit == true
                          ? "Update Client Details"
                          : "Add New Client"
                      }}
                    </h2>
                    <p>Updating user details will receive a privacy audit.</p>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >Full Name</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Full Name"
                      >
                        <b-form-input
                          id="h-first-name"
                          v-model="fullname"
                          placeholder="First Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-gender"
                        >Gender</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Gender"
                        :clearabl="false"
                        :searchable="false"
                      >
                        <v-select
                          id="h-gender"
                          v-model="gender"
                          label="name"
                          :options="getOptions('gender')"
                          placeholder="Select gender"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="mt-1 font-weight-bold" for="h-goal"
                        >Goal</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Goal"
                      >
                        <v-select
                          id="h-goal"
                          v-model="goal"
                          label="name"
                          :options="getOptions('goal')"
                          @input="handleWeeklyGoal"
                          placeholder="Select your goal"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                  <b-col cols="6" :class="goal && goal.name!= 'Maintain Weight'?'':'d-none'">
                    <b-form-group>
                      <label class="font-weight-bolder mt-1" for="h-goal">Weekly Goal</label>
                        <v-select
                          id="h-goal"
                          v-model="weekly_goal"
                          label="name"
                          :options="goal && goal.name=='Gain Weight'? getOptions('gainWeightData') :getOptions('loseWeightData') "
                          placeholder="Select your weekly goal"
                        ></v-select>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >Create UserID</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="UserID"
                      >
                        <b-form-input
                          type="text"
                          autocomplete="new-text"
                          v-model="Username"
                          maxlength="20"
                          placeholder="UserId"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >Birth Date</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Date of Birth"
                      >
                      <!-- <flat-pickr
                          v-model="dob"
                          style="background-color: transparent"
                          class="form-control "
                          placeholder="DD/MM/YYYY"
                          :config='{
                            dateFormat: "m/d/Y",
                            maxDate: "today"
                          }'
                        /> -->
                         <datepicker v-model="dob" 
                          inputClass="form-control bg-transparent"
                          format="dd/MM/yyyy"
                          :disabled-dates="disabledDates"
                          ></datepicker>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-height"
                        >Height</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="Height"
                      >
                        <b-form-input
                          id="h-height"
                          maxlength="5"
                          placeholder="158 Cm"
                          v-model="height"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-weight"
                        >Current Weight</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Current Weight"
                      >
                        <b-form-input
                          id="h-weight"
                          v-model="weight"
                          placeholder="60Kg"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>  
                    <b-col cols="6">
                      <b-form-group>
                        <label class="font-weight-bolder mt-1" for="h-weight"
                          >Goal Weight</label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Goal Weight"
                        >
                          <b-form-input
                            id="h-weight"
                            v-model="goal_weight"
                            placeholder="60Kg"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-email"
                        >Email</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|email"
                        name="Email"
                      >
                        <b-form-input
                          id="h-email"
                          maxlength="30"
                          placeholder="Email"
                          type="email"
                          v-model="email"
                        />
                        <small
                        v-if="con_email!== email"
                        class="text-danger"
                        >Email and Confirm email must be
                        same.</small
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-email"
                        >Confirm Email</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|email"
                        name="Confirm Email"
                      >
                        <b-form-input
                          id="h-email"
                          v-model="con_email"
                          placeholder="Email"
                          type="email"
                          maxlength="50"
                        />
                        <small
                          v-if="con_email!== email"
                          class="text-danger"
                          >Email and Confirm email must be
                          same.</small
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-contact"
                        >Contact</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="Contact"
                      >
                        <b-form-input
                          id="h-contact"
                          maxlength="15"
                          v-model="contact"
                          placeholder="Contact No."
                        />
                        <small
                        v-if="con_contact!== contact"
                        class="text-danger"
                        >Contact no. and Confirm contact no. must be
                        same.</small
                      >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-contact"
                        >Confirm Contact</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="Confirm Contact"
                      >
                        <b-form-input
                          id="h-contact"
                          maxlength="15"
                          v-model="con_contact"
                          placeholder="Contact No."
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="con_contact!== contact"
                          class="text-danger"
                          >Contact no. and Confirm contact no. must be
                          same.</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" 
            :class="ifEdit?'d-none':''"
            >
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-pass"
                  >Password</label
                >
                <validation-provider
                  #default="{ errors }"
                  :rules="ifEdit ? '':'required|password'"
                  name="password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="h-pass"
                      maxlength="20"
                      v-model="password"
                      autocomplete="new-password"
                      :type="passwordIcon"
                      name="Password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="passwordMismatch" class="text-danger"
                    >Password and Confirm Password must be same.</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" :class="ifEdit?'d-none':''">
              <b-form-group>
                <label class="mt-1 font-weight-bolder" for="h-pass-con"
                  >Confirm Password</label
                >
                <validation-provider
                  #default="{ errors }"
                  :rules="ifEdit ? '':'required|password'"

                  name="Confirm"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="h-pass-con"
                      maxlength="20"
                      autocomplete="new-password"
                      v-model="confirm_password"
                      :type="confirmpasswordIcon"
                      name="Confirm Password"
                      placeholder="Confirm Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="ConfirmIcon"
                        @click="toggleConfirmPassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="passwordMismatch" class="text-danger"
                    >Password and Confirm Password must be same.</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-languag"
                        >Language</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        name="Language"
                      >
                        <b-form-input
                          id="h-language"
                          :value="language"
                          disabled
                          label="name"
                          placeholder="English"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-country"
                        >Country</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Country"
                      >
                        <v-select
                          v-model="country"
                          label="name"
                          :searchable="false"
                          :options="getOptions('country')"
                          placeholder="Select Country"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bold mt-1"
                        >Medical Injuries</label
                      >
                        <b-form-textarea
                          v-model="injuries"
                          placeholder="Medical Injuries"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bold mt-1"
                        >Perpose of Workout</label
                      >
                        <b-form-textarea
                          v-model="purpose_of_workout"
                          placeholder="Perpose Of Workout"
                        />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="d-flex justify-content-center">
                    <h2 class="font-weight-bold mt-1">Membership Details</h2>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-goal"
                        >Select Plans</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Select Plan"
                      >
                        <v-select
                          id="h-plan"
                          v-model="plans"
                          label="name"
                          :options="getOptions('plan')"
                          placeholder="Select Your Plan"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col md="12" class="d-flex justify-content-center mt-2">
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled='saving'
                      class="button font-weight-bold mt-1 mr-1"
                      @click.prevent="handleSubmitForm($event)"
                    >
                      Update
                    </b-button>
                    <b-button
                      type="reset"
                      variant="primary"
                      @click="closeModel"
                      class="button font-weight-bold mt-1"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-modal>
            <b-col md="6" class="d-flex mt-1">
              <div class="w-100 d-flex justify-content-end">
                <div>
                  <v-select
                    style="width: 200px"
                    placeholder="Search..."
                    :options="
                      searchTerm.name === 'Goal'
                        ? getOptions('goal')
                        : staticUsers
                    "
                    :label="searchTerm.displayName"
                    class="d-inline-block mr-1"
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div>
                <!-- <div v-else>
                  <v-select
                    style="width: 200px;"
                    placeholder="Search..."
                    :options="getOptions('client')"
                    :label="searchTerm.displayName"
                    class="d-inline-block mr-1"
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div> -->
                <div class="ml-1">
                  <v-select
                    style="width: 150px !important"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :searchable="false"
                    v-model="searchTerm"
                    placeholder="Select"
                    class="per-page-selector d-inline-block mx-50"
                    label="name"
                    :options="searchOptions"
                    @input="changeSearch()"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          :busy="isLoading"
          ref="refUserListTable"
          class="position-relative"
          :items="staticUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          @sort-changed="onSortChanged"
        >
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(id)="data">
            <span class="text-primary font-weight-bold button">
              {{ data.index + 1 }}
            </span>
          </template>
          <!-- <template #thead-top="{ fields }">
          <tr>
            <th v-for="(field, index) in fields" :key="index">
            <template v-if="field.key == 'id' || field.key == 'Action' ">
                &nbsp;
              </template>
              <template v-else-if="field.type == 'dropdown'">
                <v-select :options="getOptions('goal')"
                  :label="field.displayName" 
                  v-model="columnFilters[field.key]"
                  multiple
                  @input="filterTable()"
                   />
              </template>
              <template v-else>
                <b-form-input
                  v-model="columnFilters[field.key]"
                  @input="filterTable()"
                  placeholder="Search..."
                />
              </template>
            </th>
          </tr>
        </template> -->
          <!-- {{ field.label }} -->
          <template #cell(f_name)="data">
            <div class="font-weight-bolder button d-flex align-items-center">
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="data.item.image"
                  :text="
                    data.item.f_name ? data.item.f_name.substring(0, 1) : ''
                  "
                />
              </span>
              {{ data.item.f_name }}
            </div>
          </template>
          <template #cell(goal)="data">
            <div class="font-weight-bolder button">
              <span class="mr-1">
                <!-- {{ data.length && }} -->
                {{
                  data.item &&
                  data.item.customer_goal && data.item.customer_goal.goal
                    ? data.item.customer_goal.goal.name
                    : ""
                }}
              </span>
            </div>
          </template>
          <template #cell(phone)="data">
            <div class="font-weight-bolder button">
              <span class="mr-1">
                {{ data.item.phone }}
              </span>
            </div>
          </template>
          <!-- <template #cell(Trainer)="data">
            <div class="d-flex w-100 align-items-center">
              <div class="mr-1">
                <b-avatar size="32" :src="data.item.avatar" :text="data.item.Trainer" />
              </div>
              <div>
                <p class="m-0">{{ data.item.Trainer }}</p>
                <small>Body Building</small>
              </div>
            </div>
          </template> -->
          <template #cell(progress)="data">
            <div class="d-flex align-items-center">
              <span
                class="font-weight-bold"
                style="font-size: 15px"
                @input="filterTable()"
                >{{ data.item.progress ? data.item.progress.toFixed(2):0  }}%</span
              >
              <b-progress
                :value="data.item.progress"
                class="w-100 ml-1"
                :variant="data.item.progress < 50 ? 'danger' : 'success'"
              ></b-progress>
            </div>
          </template>
          <template #cell(Action)="data">
            <div class="d-flex">
              <!-- <feather-icon
                icon="MailIcon"
                size="22"
                class="m-1 cursor-pointer"
              /> -->
              <feather-icon
                icon="EditIcon"
                size="22"
                class="m-1 cursor-pointer"
                @click="handleEditForm(data.item)"
              />
              <feather-icon
                icon="Trash2Icon"
                size="22"
                class="m-1"
                @click="removeRow(data.item.id)"
                style="cursor: pointer"
              /> <feather-icon
                icon="EyeIcon"
                size="22"
                class="m-1"
                @click="$router.push(`/clientmanagement/${data.item.id}`)"
                style="cursor: pointer"
              />
            </div>
          </template>
        </b-table>
        <div class="w-100 d-flex justify-content-between p-1">
          <!-- Pagination -->
          <div>
            <span class="text-nowrap">
              Showing {{ paginationStart }} to {{ paginationEnd }} of
              {{ paginationTotal }}</span
            >
          </div>
          <b-pagination
            :total-rows="paginationTotal"
            :per-page="per_page"
            v-model="paginationValue"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="changePagination()"
          >
            <!-- <div>
              <button
                @click="goToPage(paginationValue - 1)"
                :disabled="paginationValue === 1"
              >
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </button>
              <button
                @click="goToPage(paginationValue + 1)"
                :disabled="paginationValue * per_page >= paginationTotal"
              >
                <feather-icon icon="ChevronRightIcon" size="18" />
              </button>
            </div> -->
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BInputGroup,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BForm,
  BInputGroupAppend,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "@/component/axios";
import _ from "lodash";
import moment from "moment";
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    BInputGroup,
    BSpinner,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    required,
    BCard,
    BRow,
    BCol,
    BForm,
    moment,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    Datepicker,
    BFormTextarea
  },
  data() {
    return {
      columnFilters: {},
      purpose_of_workout:'',
      injuries:'',
      weekly_goal:'',
      // tableColumns: ['NO', 'Client', 'Goal', 'Progress', 'Contact', 'Action'],
      tableColumns: [
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        {
          key: "f_name",
          label: "Client",
          sortable: true,
        },
        {
          key: "goal",
          label: "GOAL",

          type: "dropdown",
          displayName: "name",
        },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'balance', sortable: true },
        {
          key: "progress",
          label: "Progress",
          sortable: true,
        },
        {
          key: "phone",
          label: "Contact",
          sortable: true,
        },
        { key: "Action", label: "Action" },
      ],
      searchTerm: {
        name: "Client",
        value: "customers",
        type: "dropdown",
        displayName: "f_name",
      },
      search: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      isSortDirDesc: true,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      AddNewUser: false,
      fullname: "",
      gender: "",
      isLoading: false,
      goal: "",
      height: "",
      weight: "",
      email: "",
      con_email:'',
      Username: "",
      contact: "",
      con_contact:'',
      language: "English",
      country: "",
      langOption: [],
            password: "",
      confirm_password: "",
      passwordMismatch: false,
      passwordIcon: "password",
      confirmpasswordIcon: "password",

      genderOption: [],
      contryOption: [],
      plansOption: [],
      goalOption: [],
      plans: "",
      originalDate:moment().format("DD/MM/YYYY"),
      staticUsers: [],
      ifEdit: false,
      id: 0,
      customerGoal: [],
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      params: {},
      saving:false,
      dob:'',
      disabledDates:{
        from: '',
      },
      goal_weight:'',
      searchOptions: [
        {
          name: "Client",
          value: "customer",
          type: "dropdown",
          displayName: "f_name",
        },
        {
          name: "Goal",
          value: "goal",
          type: "dropdown",
          displayName: "name",
          storename: "goal",
        },
      ],
      trainerID: "",
    };
  },
  mounted() {
    // this.getTrainerData();
    this.getClientData();
    let year = new Date().getFullYear() -10
    this.disabledDates.from =new Date(new Date().setFullYear(year));
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
   watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
    // '$store.state.app.trainers':{
    //   handler:'getallTrainer',
    //   immediate:true
    // }
  },
  methods: {
 toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },
    handleWeeklyGoal(){
      this.weekly_goal =''
    },
     validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getClientData();
    },
    // filterTable :_.debounce(function (){
    //   this.params={}
    //   for(let key in this.columnFilters){
    //     if(this.columnFilters[key] && typeof this.columnFilters[key] == 'object'){
    //       this.params[key] = this.columnFilters[key].map((inner) =>inner.id).join(',')
    //     }
    //     else{
    //       this.params[key] = this.columnFilters[key]
    //     }
    //   }
    //   this.params.page = this.paginationValue
    //   this.getClientData()
    // },1000),
    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getClientData();
      }
    },
    async handleEditForm(item) {
      this.ifEdit = true;
      this.AddNewUser = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/show/${item.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => {
          // console.log(res, "all response");
          let data = res.data.data.user;
          const customerPlan = res.data.data.customerPlan?res.data.data.customerPlan.plans:{};
          this.AddNewUser = true;
          this.ifEdit = true;
          this.fullname = data.f_name;
          this.gender = data.gender;
          this.goal =
            data && data.customer_goal 
              ? data.customer_goal.goal
              : "";
          this.height = data.height;
          this.weight =
            data && data.customer_goal
              ? data.customer_goal.current_weight
              : "";
          this.email = data.email;
          this.con_email = data.email;
          this.contact = data.phone;
          this.con_contact = data.phone;
          this.language = "English";
          this.country = data.country;
          this.plans = customerPlan;
          this.Username = data.username;
          this.weekly_goal = data.customer_goal ? (data.customer_goal.weeklygainweight??data.customer_goal.weeklyloseweight):''; 
          this.dob = data.dob && data.dob !=='0000-00-00' ? moment(data.dob)._d:''
          this.id = data.id;
          this.goal_weight =data.customer_goal ? data.customer_goal.goal_weight : "";
          this.password = "";
          this.confirm_password = "";
          this.purpose_of_workout=data.purpose_of_workout
          this.injuries=data.injuries
        })
        .catch((error) => {
          console.error("Error update user:", error);
        });
    },
    changePagination() {
      this.getClientData();
    },
    changeSearch() {
      this.search = "";
      if (this.search) {
        this.getClientData();
      }
    },
    async getClientData() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;

      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      } else if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      // console.log(this.search.id ? this.search.id:this.search  )
      // let params = `?page=${}&per_page=${}${
      //   this.search ? "&search=" + (this.search.id ? this.search.id:this.search  ) : ""
      // }${
      //   this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/show/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.trainerID = json?.data.data.trainer;
          this.staticUsers = json?.data.data.trainer.trainers_customers;
          this.customerGoal =
            this.staticUsers &&
            this.staticUsers.length > 0 &&
            this.staticUsers[0].customer_goal
              ? this.staticUsers[0].customer_goal?.goal?.name
              : "No customer or goal information available";
          this.pagination = json?.data.data.pagination;
          this.paginationTotal = this.pagination.total;
          this.paginationStart = this.pagination.current_page;
          this.paginationEnd = this.pagination.per_page;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSearch: _.debounce(function () {
      this.getClientData();
    }, 1000),

    resetModal() {
      this.AddNewUser = false;
      this.ifEdit = false;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.email = "";
      this.con_email = "";
      this.contact = "";
      this.con_contact = "";
      this.language = "";
      this.country = "";
      this.plans = "";
      this.id = "";
      this.Username = "";
      this.dob = moment().subtract(16,'year')._d
      this.goal_weight ="";
      this.password = "";
      this.confirm_password = "";
      this.purpose_of_workout = ''
      this.injuries = ''


    },

    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}customer/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.staticUsers.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.staticUsers.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.$forceUpdate();
                  this.getClientData();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    openProfile(item) {
      const userId = item.id;
      this.$router.push({ path: `/trainermanagement/${userId}` });
    },
    openModal() {
      this.ifEdit = false;
      this.AddNewUser = true;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.email = "";
      this.con_email = "";
      this.contact = "";
      this.con_contact = "";
      this.language = "";
      this.country = "";
      this.plans = "";
      this.id = 0;
      this.Username = "";
      this.weekly_goal=''
      this.dob = moment().subtract(16,'year')._d
      this.goal_weight =''
       this.password = "";
      this.confirm_password = "";
      this.purpose_of_workout='';
      this.injuries='';


    },
    handleSubmitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success && !this.passwordMismatch && this.con_contact == this.contact && this.email == this.con_email) {
          await this.handleForm();
        }
        else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        
      });
    },
    handleForm() {
      let endDate;

      switch (this.plans.id) {
        case 1:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(1, "month")
            .format("YYYY/MM/DD");
          break;
        case 2:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(3, "months")
            .format("YYYY/MM/DD");
          break;
        case 3:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(6, "months")
            .format("YYYY/MM/DD");
          break;
        case 4:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(9, "months")
            .format("YYYY/MM/DD");
          break;
        case 5:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(12, "months")
            .format("YYYY/MM/DD");
          break;
        default:
          // Handle other cases or set a default behavior
          break;
      }
      this.saving = true
      const customerData = {
        f_name: this.fullname,
        gender: this.gender.id,
        goal_id: this.goal.id,
        height: this.height,
        current_weight: this.weight,
        email: this.email,
        username: this.Username,
        phone: this.contact,
        language: "English",
        country: this.country.id,
        plan_id: this.plans.id,
        trainer_id: this.trainerID.id,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: endDate,
        dob:moment(this.dob).format('yyyy-MM-DD'),
        goal_weight:this.goal_weight,
        trainer_id:this.$route.params.id,
        password: this.password,
        confirm_password: this.confirm_password,
        purpose_of_workout:this.purpose_of_workout,
        injuries:this.injuries
      };


      if(this.goal && this.goal.name =='Gain Weight'){
        customerData.gain_weight_id = this.weekly_goal?this.weekly_goal.id:''
      }
      else if(this.goal && this.goal.name =='Lose weight'){
        customerData.lose_weight_id = this.weekly_goal?this.weekly_goal.id:''
      }

      const requestOptions = {
        method: `${this.ifEdit ? "put" : "post"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: this.ifEdit
          ? `${this.baseApi}customer/update/${this.id}`
          : `${this.baseApi}customer/add`,
        data: JSON.stringify(customerData),
      };

      axios(requestOptions)
        .then((response) => {
          if (response.data.success === false) {
            // Display warning message for failure
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: response.data.message
                ? `${response.data.message}`
                : "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else if (response.data.success === true) {
            this.$swal({
              title: "Submitted",
              text: response.data.message
                ? `${response.data.message}`
                : response.data.success
                ? `${response.data.success}`
                : "Update Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.AddNewUser = false;
            this.$refs.myForm.reset();
            this.getClientData();

          }
          this.$forceUpdate();
        })
        .catch((error) => {
          let string = "";
          if (error.response.data.errors) {
            Object.values(error.response.data.errors).map((item) => {
              string += `${item[0]}\n`;
            });
          }
          if (error.response.data.message) {
            string = error.response.data.message;
          }
          this.$swal({
            title: "Error",
            html: string,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });

          console.error("Error adding customer:", error);
        })
        .finally(()=>{
          this.saving = false

        })
    },
    closeModel() {
      this.AddNewUser = false;
    },
    // submit() {
    //   this.$refs.simpleRules.validate().then(async (success) => {
    //   })
    // }
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.button {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  